import React, { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { AiOutlineBank } from "react-icons/ai";
import { FaMoneyBillWave } from "react-icons/fa";
import { IoWalletOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CircleItem from "components/Finances/Common/CircleItem";
import { FinanceAccount, FinanceAccountType } from "client/data-contracts";
import { AccountDragObject, FinanceDNDItemTypes } from "utils/finances";

interface FavoriteAccountsItemProps {
  entity: FinanceAccount;
}

function getIcon(account: FinanceAccount): React.ReactNode {
  if (account.account_type === FinanceAccountType.CASH) {
    return <IoWalletOutline />;
  }
  if (account.account_type === FinanceAccountType.BANK) {
    return <AiOutlineBank />;
  }
  return <FaMoneyBillWave />;
}

export default function CircleFinanceAccountsItem({ entity }: FavoriteAccountsItemProps): React.JSX.Element {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag(() => ({
    type: FinanceDNDItemTypes.ACCOUNT,
    item: { entity },
  }));

  const [, drop] = useDrop(
    () => ({
      accept: FinanceDNDItemTypes.ACCOUNT,
      drop: (source: AccountDragObject) => {
        const target = entity;
        // we don't want to transfer from to same accounts
        if (source.entity.id === target.id) {
          return;
        }
        navigate(`/finances/transactions/transfer/${source.entity.id}/${target.id}`);
      },
    }),
    []
  );

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      event.preventDefault();
    };

    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault();
    };

    const element = ref.current;
    if (element) {
      element.addEventListener("touchstart", handleTouchStart);
      element.addEventListener("touchmove", handleTouchMove);
    }

    return () => {
      if (element) {
        element.removeEventListener("touchstart", handleTouchStart);
        element.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  function attachRef(el: HTMLDivElement) {
    (ref.current as HTMLDivElement | null) = el;
    drag(el);
    drop(el);
  }

  return (
    <span ref={attachRef}>
      <CircleItem
        name={entity.name}
        icon={getIcon(entity)}
        amount={entity.balance as number}
        currency={entity.currency}
      />
    </span>
  );
}
