import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Income, IncomeListSchema, IncomeSchema, MoneyStat, TransactionSchema } from "client/data-contracts";
import type { RootState } from "store";
import { Nullish } from "utils/base";

interface IncomeState {
  incomeResponse: Nullish<IncomeListSchema>;
}

const initialState: IncomeState = {
  incomeResponse: null,
};

const sortFunction = (a: Income, b: Income) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const incomeSlice = createSlice({
  name: "income",
  initialState,
  reducers: {
    loadIncome: (state, action: PayloadAction<IncomeListSchema>) => {
      state.incomeResponse = action.payload;
    },
    deleteIncome: (state, action: PayloadAction<Income>) => {
      if (!state.incomeResponse) {
        return;
      }
      state.incomeResponse.income_list = state.incomeResponse.income_list.filter(
        (f) => f.id !== action.payload.id
      );
    },
    updateIncome: (state, action: PayloadAction<Income>) => {
      if (!state.incomeResponse) {
        return;
      }
      state.incomeResponse.income_list = state.incomeResponse.income_list
        .map((item) => (item.id === action.payload.id ? action.payload : item))
        .sort(sortFunction);
    },
    createIncome: (state, action: PayloadAction<Income>) => {
      if (!state.incomeResponse) {
        return;
      }
      state.incomeResponse.income_list = [...state.incomeResponse.income_list, action.payload];
    },
    updateIncomeEarnings: (
      state,
      action: PayloadAction<{ incomeId: string; transaction: TransactionSchema }>
    ) => {
      if (!state.incomeResponse) {
        return;
      }

      let defaultCurrencyAmount;
      let currencyAmount;
      let currency;
      state.incomeResponse.income_list = state.incomeResponse.income_list.map((income) => {
        const updatedIncome = income;
        if (updatedIncome.id !== action.payload.incomeId) {
          return updatedIncome;
        }

        if (!updatedIncome.earnings) {
          updatedIncome.earnings = 0;
        }

        defaultCurrencyAmount =
          action.payload.transaction.equivalent_amount_in_usd || action.payload.transaction.amount;
        currencyAmount = action.payload.transaction.amount;
        currency = action.payload.transaction.account?.currency;

        updatedIncome.earnings += currencyAmount;
        updatedIncome.earnings_currency = currency;

        return updatedIncome;
      });

      if (defaultCurrencyAmount) {
        state.incomeResponse.monthly_earnings_stat.default_currency_amount += defaultCurrencyAmount;
      }

      if (currencyAmount && currency) {
        if (!state.incomeResponse.monthly_earnings_stat.all_currencies_amount[currency]) {
          state.incomeResponse.monthly_earnings_stat.all_currencies_amount[currency] = 0;
        }
        state.incomeResponse.monthly_earnings_stat.all_currencies_amount[currency] += currencyAmount;
      }
    },
  },
});

export const { loadIncome, createIncome, deleteIncome, updateIncome, updateIncomeEarnings } =
  incomeSlice.actions;

export const selectIncomeList = (state: RootState): IncomeSchema[] => {
  if (!state.income.incomeResponse) {
    return [];
  }
  return state.income.incomeResponse.income_list;
};

export const selectMonthlyEarningsStat = (state: RootState): Nullish<MoneyStat> => {
  if (!state.income.incomeResponse) {
    return null;
  }
  return state.income.incomeResponse.monthly_earnings_stat;
};

export default incomeSlice.reducer;
