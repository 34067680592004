import "components/App/Parts/Footer.scss";

import React from "react";

export default function Footer(): React.JSX.Element {
  return (
    <footer className="footer">
      <div className="container text-end">
        <span className="small text-muted">{import.meta.env.VITE_VERSION || "dev"} version</span>
      </div>
    </footer>
  );
}
