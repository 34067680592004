import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TransactionTag } from "client/data-contracts";
import TransactionsTagsDisplay from "components/Finances/Transactions/TransactionsTagsDisplay";
import api from "utils/api";

interface TransactionsTagsProps {
  selectedTags: TransactionTag[];
  setSelectedTags: Dispatch<SetStateAction<TransactionTag[]>>;
}

export default function TransactionsTagsForm({
  selectedTags,
  setSelectedTags,
}: TransactionsTagsProps): React.JSX.Element {
  const [selectInputValue, setSelectInputValue] = useState("");
  const [tagOptions, setTagOptions] = useState<TransactionTag[]>([]);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfUniqueTransactionTags();
      setTagOptions(response.data);
    })();
  }, [setTagOptions]);

  useEffect(() => {
    (async () => {
      if (selectedTags.length === 0) {
        return;
      }
      if (tagOptions.length === 0) {
        return;
      }
      const hasSelectedTagsInOptions = selectedTags.every((selectedTag) =>
        tagOptions.some((tag) => tag.name === selectedTag.name)
      );
      if (hasSelectedTagsInOptions) {
        // remove selected tags from options
        setTagOptions(
          tagOptions.filter((tag) => !selectedTags.some((selectedTag) => selectedTag.name === tag.name))
        );
      }
    })();
  }, [selectedTags, tagOptions]);

  const handleNewTagCreate = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    const target = event.target as HTMLInputElement;
    const newTagName = target.value;
    target.value = "";

    setSelectedTags([...selectedTags, { name: newTagName }]);
    setTagOptions([...tagOptions, { name: newTagName }]);

    event.preventDefault();
    event.stopPropagation();
  };

  const handleSelectTagEvent = (event: React.FormEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLInputElement;
    const tagName = target.value;
    setSelectedTags([...selectedTags, { name: tagName }]);
    setTagOptions(tagOptions.filter((tag) => tag.name !== tagName));
    setSelectInputValue("");
  };

  const handleDeleteTag = (tagToDelete: TransactionTag) => {
    setSelectedTags(selectedTags.filter((tag) => tag.name !== tagToDelete.name));
    // add back tag to options after it was deleted
    setTagOptions([...tagOptions, tagToDelete]);
  };

  const tagSelectOptions = tagOptions.map((tag) => (
    <option value={tag.name} key={tag.name}>
      {tag.name}
    </option>
  ));

  return (
    <div className="mb-1">
      <label className="form-label" htmlFor="transactions-tags-input">
        <div className="mb-1 small" data-test="selected-tags">
          Selected tags: <TransactionsTagsDisplay tags={selectedTags} onDelete={handleDeleteTag} />
        </div>
        <input
          placeholder="Type to create new tag"
          type="text"
          className="form-control form-control-sm mb-1 mt-2"
          id="transactions-tags-input"
          data-test="transactions-tags-input"
          onKeyDown={handleNewTagCreate}
        />
        <select
          className="form-control form-control-sm"
          value={selectInputValue}
          onChange={handleSelectTagEvent}
        >
          <option value="">or select a tag from list</option>
          {tagSelectOptions}
        </select>
      </label>
    </div>
  );
}
