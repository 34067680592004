import { useParams } from "react-router-dom";
import { selectFinanceCategories } from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import { useAppSelector } from "hooks";
import getById from "utils/crud";
import React, { useEffect, useState } from "react";
import { Nullish } from "utils/base";
import { FinanceCategory } from "client/data-contracts";
import FinanceCategoriesForm from "components/Finances/FinanceCategories/Form/FinanceCategoriesForm";

export default function FinanceCategoriesEdit(): React.JSX.Element {
  const { financeCategoryId } = useParams();
  const financeCategories = useAppSelector(selectFinanceCategories);
  const [financeCategory, setFinanceCategory] = useState(null as Nullish<FinanceCategory>);

  useEffect(() => {
    if (!financeCategory && financeCategories.length > 0) {
      setFinanceCategory(getById(financeCategories, financeCategoryId as string));
    }
  }, [financeCategory, financeCategories, financeCategoryId]);

  return (
    <>
      <h3 data-test="finance-categories-edit-title">Edit finance category</h3>
      {financeCategory && <FinanceCategoriesForm entity={financeCategory} />}
    </>
  );
}
