import React, { useEffect, useState } from "react";
import api from "utils/api";
import { BookSchema, BookSource } from "client/data-contracts";
import { displayDate } from "utils/date";
import { Link } from "react-router-dom";

function getSearchURL(source: BookSource, title: string): string {
  if (source === BookSource.Fantlab) {
    return `https://fantlab.ru/searchmain?searchstr=${encodeURIComponent(title)}`;
  }
  if (source === BookSource.AuthorToday) {
    return `https://author.today/search?category=works&q=${encodeURIComponent(title)}`;
  }
  if (source === BookSource.Goodreads) {
    return `https://www.goodreads.com/search?search_type=books&q=${encodeURIComponent(title)}`;
  }
  return "";
}

export default function BookList(): React.JSX.Element {
  const [books, setBooks] = useState<BookSchema[]>([]);

  useEffect(() => {
    (async () => {
      const response = await api.books.getListOfBooks();
      setBooks(response.data);
    })();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12 col-lg-12 d-flex">
          <h3>Books</h3>
          <Link
            to="/media/books/add"
            className="ms-auto btn btn-sm btn-outline-success mt-1"
            style={{ height: "31px" }}
          >
            Add a book
          </Link>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Score</th>
            <th>Links</th>
            <th>Date start</th>
            <th>Date end</th>
          </tr>
        </thead>
        <tbody>
          {books.map((book) => (
            <tr key={book.id}>
              <td>
                <Link to={`/media/books/edit/${book.id}`} className="text-decoration-none">
                  {book.titles[0]?.name}
                </Link>
              </td>
              <td>{book.reading_history?.[0]?.status}</td>
              <td>{book.reading_history?.[0]?.score}</td>
              <td className="d-flex gap-2">
                {Object.values(BookSource).map((source) => {
                  const link = book.external_links?.find((l) => l.source_name === source);
                  return link ? (
                    <a
                      key={source}
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-primary"
                    >
                      [{source[0]}]
                    </a>
                  ) : (
                    <a
                      key={source}
                      href={getSearchURL(source, book.titles[0]?.name)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none text-secondary"
                    >
                      [{source[0]}]
                    </a>
                  );
                })}
              </td>
              <td>{displayDate(book.reading_history?.[0]?.date_start)}</td>
              <td>{displayDate(book.reading_history?.[0]?.date_end)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
