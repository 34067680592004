import React, { useEffect, useState } from "react";
import { Income } from "client/data-contracts";
import MoneyStatDisplay from "components/Finances/Common/MoneyStatDisplay";
import IncomeList from "components/Finances/Income/IncomeList";
import IncomeModalForm from "components/Finances/Income/IncomeModalForm";
import { loadIncome, selectMonthlyEarningsStat } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";

export default function IncomeMain(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const [showIncomeFormModal, setShowIncomeFormModal] = useState(false);
  const [incomeFormEntity, setIncomeFormEntity] = useState<Income | undefined>(undefined);
  const monthlyEarningsStat = useAppSelector(selectMonthlyEarningsStat);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfIncome();
      dispatch(loadIncome(response.data));
    })();
  }, [dispatch]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-2 d-flex">
          <h3>Income</h3>
          <button
            type="button"
            data-test="income-create-button"
            className="ms-4 btn btn-sm btn-outline-success ml-auto mt-1"
            style={{ height: "31px" }}
            onClick={() => {
              setIncomeFormEntity(undefined);
              setShowIncomeFormModal(true);
            }}
          >
            Create
          </button>
        </div>
        <div
          className="col-12 col-lg-10 text-secondary small text-lg-end text-start pt-3"
          data-test="income-stat"
        >
          <MoneyStatDisplay
            roundBigNumber={false}
            showOnlyTotal={false}
            displayPrefix
            stat={monthlyEarningsStat}
          />
        </div>
      </div>
      <IncomeList setShowIncomeFormModal={setShowIncomeFormModal} setIncomeFormEntity={setIncomeFormEntity} />
      <IncomeModalForm
        showModal={showIncomeFormModal}
        setShowModal={setShowIncomeFormModal}
        onHide={() => setShowIncomeFormModal(false)}
        entity={incomeFormEntity}
      />
    </>
  );
}
