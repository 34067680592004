import "components/Finances/FinancesMainNavigation.scss";

import React, { SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { NavOption } from "utils/base";

interface FinancesMainNavigationProps {
  options: NavOption[];
}

export default function FinancesMainNavigation({ options }: FinancesMainNavigationProps): React.JSX.Element {
  const navigate = useNavigate();

  function handleClickEvent(event: SyntheticEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();

    const currentOption = options[index];
    // navigate to clicked route
    navigate(currentOption.link);
    return false;
  }

  const navOptions = options.map((option, index) => (
    <div className="nav-item" key={option.link}>
      <a
        href={option.link}
        onClick={(event) => {
          handleClickEvent(event, index);
        }}
        className={["nav-link", option.isActive ? "active" : ""].join(" ")}
      >
        {option.name}
      </a>
    </div>
  ));

  return (
    <div className="finances-navigation">
      <div className="navbar d-inline-block d-sm-none">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMenu"
          aria-controls="toggleMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>
      <div className="collapse" id="toggleMenu" data-test="finances-navigation-menu">
        <div className="nav nav-pills custom-nav-bar">{navOptions}</div>
      </div>
    </div>
  );
}
