import "components/Finances/FinanceCategories/FinanceCategoriesList.scss";

import React from "react";
import FinanceCategoriesItem from "components/Finances/FinanceCategories/FinanceCategoriesItem";
import { selectFinanceCategories } from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import { useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";

export default function FinanceCategoriesList(): React.JSX.Element {
  const navigate = useNavigate();
  const financeCategories = useAppSelector(selectFinanceCategories);

  const financeCategoriesItems = financeCategories.map((item) => (
    <span data-test="finance-category-item" className="col-3 col-sm-2 col-md-2 col-xl-1" key={item.id}>
      <FinanceCategoriesItem
        entity={item}
        editAction={() => {
          navigate(`/finances/finance-categories/edit/${item.id}`);
        }}
      />
    </span>
  ));

  return <div className="mb-2 mt-2 finance-categories row">{financeCategoriesItems}</div>;
}
