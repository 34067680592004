import React, { useState } from "react";
import CustomModal from "components/Common/CustomModal";
import { ConfirmDeleteModalInputProps, ModalVisibilityProps } from "utils/base";

interface ConfirmDeleteModalProps extends ModalVisibilityProps {
  onHide(): void;
  inputProps: ConfirmDeleteModalInputProps;
}

export default function ConfirmDeleteModal({
  showModal,
  setShowModal,
  onHide,
  inputProps,
}: ConfirmDeleteModalProps): React.JSX.Element {
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);
  const handleDeleteButton = () => {
    setDeleteButtonDisabled(true);
    inputProps.deleteAction(inputProps.toDeleteId, setDeleteButtonDisabled);
    onHide();
  };
  return (
    <CustomModal
      modalTitle="Confirm deletion"
      modalName="delete"
      modalBody={
        <p data-test="delete-modal-body">
          Are you sure you want to delete &quot;{inputProps.toDeleteName}&quot;?
        </p>
      }
      submitButtonDisabled={deleteButtonDisabled}
      showModal={showModal}
      setShowModal={setShowModal}
      submitButtonType="button"
      submitButtonCallback={handleDeleteButton}
      submitButtonText="Delete"
      submitButtonClass="btn-outline-danger"
    />
  );
}
