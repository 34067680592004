import "components/Login/Login.scss";

import axios from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React from "react";
import { object, string } from "yup";
import api from "utils/api";
import { TokenSchema } from "client/data-contracts";

interface Values {
  username: string;
  password: string;
}

const validationSchema = object({
  username: string().required("Username is required."),
  password: string().required("Enter your password."),
});

interface LoginProps {
  setToken(userToken: TokenSchema): void;
}

export default function Login({ setToken }: LoginProps): React.JSX.Element {
  const handleSubmitEvent = async (values: Values, actions: FormikHelpers<Values>) => {
    try {
      const response = await api.auth.authenticate(
        { username: values.username, password: values.password },
        { secure: false }
      );
      setToken(response.data);
      actions.setSubmitting(false);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        actions.setStatus("Username or password is invalid.");
        actions.setSubmitting(false);
      } else {
        throw error;
      }
    }
  };

  return (
    <main role="main" className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-5 text-center">
          <div>
            <h3 className="h3 mb-3 font-weight-normal" data-test="login-header">
              Sign in
            </h3>

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                username: "",
                password: "",
              }}
              onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
                handleSubmitEvent(values, actions);
              }}
            >
              {(props: FormikProps<Values>) => (
                <Form>
                  {props.status && (
                    <div className="alert alert-danger" role="alert" data-test="login-error-message">
                      {props.status}
                    </div>
                  )}

                  <div className="mb-3">
                    <Field name="username">
                      {({ field, meta }: FieldProps) => (
                        <div>
                          <input
                            type="text"
                            className={`form-control ${meta.error ? "is-invalid" : ""}`}
                            placeholder="Username"
                            data-test="login-username-input"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                          />
                          {meta.touched && meta.error && (
                            <div data-test="login-username-error" className="invalid-feedback">
                              {meta.error}
                            </div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="mb-3">
                    <Field name="password">
                      {({ field, meta }: FieldProps) => (
                        <div>
                          <input
                            type="password"
                            className={`form-control ${meta.error ? "is-invalid" : ""}`}
                            placeholder="Password"
                            data-test="login-password-input"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                          />
                          {meta.touched && meta.error && (
                            <div data-test="login-password-error" className="invalid-feedback">
                              {meta.error}
                            </div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className="mb-3">
                    <button
                      data-test="login-submit-button"
                      type="submit"
                      className="btn btn-outline-primary"
                      disabled={!props.isValid || props.isSubmitting}
                    >
                      Sign in
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </main>
  );
}
