import "components/Finances/Transactions/Form/TransactionsForm.scss";

import type { AxiosError } from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { FinanceAccount, FinanceCategory, TransactionSchema, TransactionTag } from "client/data-contracts";
import DisplayError from "components/Common/DisplayError";
import {
  loadAccounts,
  selectAccounts,
  updateAccount,
} from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import {
  loadFinanceCategories,
  selectFinanceCategories,
  updateCategoryAmountFromTransaction,
} from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import { updateIncomeEarnings } from "components/Finances/Income/IncomeSlice";
import TransactionsCommentAutocompleteField from "components/Finances/Transactions/Form/TransactionsCommentAutocompleteField";
import TransactionsTagsForm from "components/Finances/Transactions/Form/TransactionsTagsForm";
import { createTransaction, updateTransaction } from "components/Finances/Transactions/TransactionsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { Nullish, NumberFieldValue } from "utils/base";
import getById from "utils/crud";
import { convertFormDateToUTC, DATE_INPUT_FORMAT, displayDate } from "utils/date";
import dayjs from "utils/dayjs";
import { convertMoneyToNumber, convertNumberToMoney } from "utils/finances";
import Logger from "utils/logger";

interface RegularTransactionsFormProps {
  entity: TransactionSchema;
}

interface Values {
  amount: string;
  date: string;
  comment: string;
}

const validationSchema = object({
  amount: string().required(),
  date: string().required("Date is required."),
  comment: string(),
});

export default function RegularTransactionsForm({ entity }: RegularTransactionsFormProps): React.JSX.Element {
  const defaultValues = (entityForm: Nullish<TransactionSchema>): Values => {
    return {
      amount: (entityForm?.amount
        ? convertMoneyToNumber(entityForm?.amount).toString()
        : "") as NumberFieldValue,
      date: entityForm?.date ? displayDate(entityForm?.date) : dayjs().format(DATE_INPUT_FORMAT),
      comment: entityForm?.comment || "",
    } as Values;
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const financeCategories = useAppSelector(selectFinanceCategories);
  const accounts = useAppSelector(selectAccounts);

  const [formErrors, setFormErrors] = useState<Nullish<AxiosError>>(undefined);
  const [selectedTags, setSelectedTags] = useState<TransactionTag[]>([]);
  const [formValues, setFormValues] = useState<Values>(defaultValues(undefined));
  const [title, setTitle] = useState("Transaction");
  const [account, setAccount] = useState<Nullish<FinanceAccount>>(undefined);
  const [category, setCategory] = useState<Nullish<FinanceCategory>>(undefined);
  const [comment, setComment] = useState<Nullish<string>>(undefined);

  useEffect(() => {
    if (!accounts) {
      (async () => {
        const response = await api.finance.getListOfAccounts({});
        dispatch(loadAccounts(response.data));
      })();
    }
  }, [dispatch, accounts]);

  useEffect(() => {
    if (!financeCategories) {
      (async () => {
        const response = await api.finance.getListOfFinanceCategories();
        dispatch(loadFinanceCategories(response.data));
      })();
    }
  }, [dispatch, financeCategories]);

  useEffect(() => {
    setFormValues(defaultValues(entity));

    if (entity) {
      const tags = entity.tags || [];
      setSelectedTags(tags);

      if (entity?.account_id) {
        try {
          setAccount(getById(accounts, entity?.account_id));
        } catch (error) {
          Logger.exception(error as Error);
        }
      }

      if (entity?.category_id) {
        try {
          setCategory(getById(financeCategories, entity?.category_id));
        } catch (error) {
          Logger.exception(error as Error);
        }
      }

      if (entity?.from_income?.name) {
        setTitle(`Income from ${entity.from_income.name}`);
      } else if (category && account) {
        setTitle(`${account.name} - ${category.name}`);
      }

      setComment(entity?.comment || "");
    }
  }, [entity, account, accounts, category, financeCategories]);

  const handleSubmitEvent = async (values: Values, actions: FormikHelpers<Values>) => {
    const originalTransaction = {
      ...entity,
      ...{
        amount: convertNumberToMoney(values.amount as unknown as number),
        date: convertFormDateToUTC(values.date).toISOString(),
        currency: account?.currency,
        tags: selectedTags,
        comment,
      },
    } as TransactionSchema;

    // FastAPI doesn't have request_model_exclude option
    // so we had to clean up entity on FE before sending it
    if (originalTransaction.from_income) {
      delete originalTransaction.from_income;
    }

    const action = entity?.id ? api.finance.updateTransaction : api.finance.createTransaction;
    try {
      const response = await action(originalTransaction);
      const updatedTransaction = response.data;

      if (entity?.id) {
        dispatch(updateTransaction(updatedTransaction));
      } else {
        dispatch(createTransaction(updatedTransaction));
      }

      if (updatedTransaction.account) {
        dispatch(updateAccount(updatedTransaction.account));
      }

      if (updatedTransaction.from_income_id) {
        dispatch(
          updateIncomeEarnings({
            incomeId: updatedTransaction.from_income_id,
            transaction: updatedTransaction,
          })
        );
      }

      if (category && entity && entity.category_id) {
        if (entity.id) {
          // when we are editing transaction
          // we should deduct amount that previously was added
          // to have correct balance
          dispatch(
            updateCategoryAmountFromTransaction({
              categoryId: entity.category_id,
              transaction: entity,
              increase: false,
            })
          );
        }

        dispatch(
          updateCategoryAmountFromTransaction({
            categoryId: entity.category_id,
            transaction: updatedTransaction,
            increase: true,
          })
        );
      }

      navigate("/finances");
    } catch (requestError) {
      setFormErrors(requestError as AxiosError);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
        handleSubmitEvent(values, actions).then();
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form className="col-lg-3">
          <DisplayError error={formErrors} />

          <div className="row">
            <div className="col-6 col-sm-7 col-lg-12">
              <p className="text-secondary small mt-2 mt-md-0" data-test="transactions-title">
                {title}
              </p>
            </div>
            <div className="col-6 col-sm-5 text-end d-block d-sm-none">
              <button
                type="button"
                className="btn btn-outline-secondary me-1"
                onClick={() => navigate("/finances")}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-outline-primary"
                disabled={!props.isValid || props.isSubmitting}
              >
                Save
              </button>
            </div>
          </div>

          <div className="mb-1">
            <Field name="amount">
              {({ field, meta }: FieldProps) => (
                <label className="form-label" htmlFor="transactions-amount-input">
                  Amount
                  <div className="input-group">
                    <span data-test="transactions-currency" className="input-group-text">
                      {account && account.currency}
                    </span>
                    <input
                      autoFocus
                      inputMode="decimal"
                      className={`form-control ${meta.error ? "is-invalid" : ""}`}
                      placeholder="How much?"
                      id="transactions-amount-input"
                      data-test="transactions-amount-input"
                      name={field.name}
                      value={field.value}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        event.target.value = event.target.value.replace(",", ".");
                        field.onChange(event);
                      }}
                      onBlur={field.onBlur}
                    />
                    <div
                      data-test="transactions-amount-error"
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {(meta.touched && meta.error && <>{meta.error}</>) || <>&nbsp;</>}
                    </div>
                  </div>
                </label>
              )}
            </Field>
          </div>

          <TransactionsCommentAutocompleteField
            account_id={entity?.account_id}
            category_id={entity?.category_id as string}
            setComment={setComment}
            comment={comment}
          />

          <div className="mb-1">
            <Field name="date">
              {({ field, meta }: FieldProps) => (
                <label className="form-label" htmlFor="transactions-date-input">
                  Date
                  <input
                    type="date"
                    className={`form-control ${meta.error ? "is-invalid" : ""}`}
                    placeholder="Date"
                    id="transactions-date-input"
                    data-test="transactions-date-input"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                  {meta.touched && meta.error && (
                    <div data-test="transactions-date-error" className="invalid-feedback">
                      {meta.error}
                    </div>
                  )}
                </label>
              )}
            </Field>
          </div>

          <TransactionsTagsForm selectedTags={selectedTags} setSelectedTags={setSelectedTags} />

          <div className="text-end mb-3">
            <button
              type="button"
              className="btn btn-outline-secondary me-3"
              onClick={() => navigate("/finances")}
            >
              Close
            </button>
            <button
              data-test="transactions-submit-button"
              type="submit"
              className="btn btn-outline-primary"
              disabled={!props.isValid || props.isSubmitting}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
