import React, { Dispatch, SetStateAction, useContext } from "react";
import { FinanceAccount } from "client/data-contracts";
import { deleteAccount, selectAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { ConfirmDeleteModalContext, ConfirmDeleteModalInputProps } from "utils/base";
import getById from "utils/crud";
import { displayMoneyWithCurrency } from "utils/finances";

interface AccountsListProps {
  setShowAccountsFormModal: Dispatch<SetStateAction<boolean>>;
  setAccountsFormEntity: Dispatch<SetStateAction<FinanceAccount | undefined>>;
}

export default function FinanceAccountsList({
  setShowAccountsFormModal,
  setAccountsFormEntity,
}: AccountsListProps): React.JSX.Element {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const { setShowConfirmDeleteModal, setConfirmDeleteModalInputProps } =
    useContext(ConfirmDeleteModalContext);

  const deleteAccountEvent = async (
    accountId: string,
    setDeleteButtonDisabled: Dispatch<SetStateAction<boolean>>
  ) => {
    const account = getById(accounts, accountId);
    await api.finance.deleteFinanceAccount(account);
    dispatch(deleteAccount(account));
    setDeleteButtonDisabled(false);
  };

  const accountRows = accounts.map((item) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>{item.account_type}</td>
      <td>{displayMoneyWithCurrency(item.balance as number, item.currency)}</td>
      <td>{item.display_level}</td>
      <td>{item.order}</td>
      <td>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-primary"
          data-test="accounts-edit-button"
          onClick={() => {
            setShowAccountsFormModal(true);
            setAccountsFormEntity(item);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-danger"
          data-test="accounts-delete-button"
          onClick={() => {
            setShowConfirmDeleteModal(true);
            setConfirmDeleteModalInputProps({
              toDeleteName: item.name,
              toDeleteId: item.id as string,
              deleteAction: deleteAccountEvent,
            } as ConfirmDeleteModalInputProps);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <table
      className="table table-responsive table-bordered table-striped table-sm"
      data-test="accounts-table"
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Balance</th>
          <th>Level</th>
          <th>Order</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {accounts.length ? (
          accountRows
        ) : (
          <tr>
            <td colSpan={6}>No data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
