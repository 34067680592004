import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  FinanceCategory,
  FinanceCategoryListSchema,
  MoneyStat,
  TransactionSchema,
} from "client/data-contracts";
import type { RootState } from "store";
import { Nullish } from "utils/base";

interface FinanceCategoriesState {
  financeCategoriesResponse: Nullish<FinanceCategoryListSchema>;
}

const initialState: FinanceCategoriesState = {
  financeCategoriesResponse: null,
};

const sortFunction = (a: FinanceCategory, b: FinanceCategory) => ((a.order || 0) > (b.order || 0) ? 1 : -1);

export const financeCategoriesSlice = createSlice({
  name: "financeCategories",
  initialState,
  reducers: {
    loadFinanceCategories: (state, action: PayloadAction<FinanceCategoryListSchema>) => {
      state.financeCategoriesResponse = action.payload;
    },
    deleteFinanceCategory: (state, action: PayloadAction<FinanceCategory>) => {
      if (!state.financeCategoriesResponse) {
        return;
      }
      state.financeCategoriesResponse.categories = state.financeCategoriesResponse.categories.filter(
        (f) => f.id !== action.payload.id
      );
    },
    updateFinanceCategory: (state, action: PayloadAction<FinanceCategory>) => {
      if (!state.financeCategoriesResponse) {
        return;
      }
      state.financeCategoriesResponse.categories = state.financeCategoriesResponse.categories
        .map((item) => (item.id === action.payload.id ? action.payload : item))
        .sort(sortFunction);
    },
    createFinanceCategory: (state, action: PayloadAction<FinanceCategory>) => {
      if (!state.financeCategoriesResponse) {
        return;
      }
      state.financeCategoriesResponse.categories = [
        ...state.financeCategoriesResponse.categories,
        action.payload,
      ].sort(sortFunction);
    },
    updateCategoryAmountFromTransaction: (
      state,
      action: PayloadAction<{ categoryId: string; transaction: TransactionSchema; increase: boolean }>
    ) => {
      if (!state.financeCategoriesResponse) {
        return;
      }
      let amountInDefaultCurrency;
      let currency;
      let currencyAmount;
      state.financeCategoriesResponse.categories = state.financeCategoriesResponse.categories.map(
        (category) => {
          const innerCategory = category;
          if (innerCategory.id !== action.payload.categoryId) {
            return innerCategory;
          }

          const defaultCurrencyAmount =
            action.payload.transaction.equivalent_amount_in_usd || action.payload.transaction.amount;

          if (!innerCategory.amount) {
            innerCategory.amount = 0;
          }

          amountInDefaultCurrency = action.payload.increase ? defaultCurrencyAmount : -defaultCurrencyAmount;
          innerCategory.amount += amountInDefaultCurrency;

          currency = action.payload.transaction.currency;
          currencyAmount = action.payload.increase
            ? action.payload.transaction.amount
            : -action.payload.transaction.amount;

          return innerCategory;
        }
      );

      if (amountInDefaultCurrency) {
        state.financeCategoriesResponse.monthly_spending_stat.default_currency_amount +=
          amountInDefaultCurrency;
      }

      if (currency && currencyAmount) {
        if (!state.financeCategoriesResponse.monthly_spending_stat.all_currencies_amount[currency]) {
          state.financeCategoriesResponse.monthly_spending_stat.all_currencies_amount[currency] = 0;
        }
        state.financeCategoriesResponse.monthly_spending_stat.all_currencies_amount[currency] +=
          currencyAmount;
      }
    },
  },
});

export const {
  loadFinanceCategories,
  createFinanceCategory,
  deleteFinanceCategory,
  updateFinanceCategory,
  updateCategoryAmountFromTransaction,
} = financeCategoriesSlice.actions;

export const selectFinanceCategories = (state: RootState): FinanceCategory[] => {
  if (!state.financeCategories.financeCategoriesResponse) {
    return [];
  }
  return state.financeCategories.financeCategoriesResponse.categories;
};

export const selectFinanceCategoriesMonthlySpendingStat = (state: RootState): Nullish<MoneyStat> => {
  if (!state.financeCategories.financeCategoriesResponse) {
    return null;
  }
  return state.financeCategories.financeCategoriesResponse.monthly_spending_stat;
};

export default financeCategoriesSlice.reducer;
