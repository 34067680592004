import "components/Finances/Transactions/Form/TransactionsForm.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

import React, { useCallback, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import api from "utils/api";
import { isDefined, Nullish } from "utils/base";

interface TransactionsCommentAutocompleteFieldProps {
  account_id: string;
  category_id: Nullish<string>;
  setComment: (comment: string) => void;
  comment: Nullish<string>;
}

export default function TransactionsCommentAutocompleteField({
  account_id,
  category_id,
  setComment,
  comment,
}: TransactionsCommentAutocompleteFieldProps): React.JSX.Element {
  const [singleSelections, setSingleSelections] = useState([] as Option[]);
  const [options, setOptions] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);

  // initial suggestions
  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfAutocompleteSuggestions({
        query: comment || "",
        account_id,
        category_id: category_id || "",
      });
      setOptions(response.data);
    })();
  }, [setOptions, account_id, category_id, comment]);

  const handleSearch = useCallback(
    (query: string) => {
      setIsLoading(true);

      (async () => {
        const response = await api.finance.getListOfAutocompleteSuggestions({
          query,
          account_id,
          category_id: category_id || "",
        });
        setOptions(response.data);
        setIsLoading(false);
      })();
    },
    [account_id, category_id]
  );

  const handleInputChange = (q: string) => {
    setComment(q);
  };

  const handleSelection = (o: Option[]) => {
    setSingleSelections(o);
    if (o.length !== 0) {
      setComment(o[0] as string);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <div className="mb-1" data-test="transactions-comment-input">
      {isDefined(comment) && (
        <AsyncTypeahead
          id="transactions-comment-input"
          filterBy={filterBy}
          minLength={0}
          delay={300}
          labelKey="name"
          defaultInputValue={comment}
          onChange={handleSelection}
          onInputChange={handleInputChange}
          onSearch={handleSearch}
          isLoading={isLoading}
          options={options}
          selected={singleSelections}
          placeholder="Shop name, product name, etc."
        />
      )}
    </div>
  );
}
