import { createContext, Dispatch, SetStateAction } from "react";

export type Nullish<T> = T | null | undefined;
export type NumberFieldValue = number | "";

export function isDefined<T>(value: T | undefined | null): value is T {
  return (value as T) !== undefined && (value as T) !== null;
}

export interface ModalVisibilityProps {
  showModal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowModal: any;
}

export interface ConfirmDeleteModalInputProps {
  deleteAction(entityId: string, setDeleteButtonDisabled: Dispatch<SetStateAction<boolean>>): void;
  toDeleteName: string;
  toDeleteId: string;
}

export const ConfirmDeleteModalContext = createContext<{
  showConfirmDeleteModal: boolean;
  setShowConfirmDeleteModal: Dispatch<SetStateAction<boolean>>;
  confirmDeleteModalInputProps: ConfirmDeleteModalInputProps;
  setConfirmDeleteModalInputProps: Dispatch<SetStateAction<ConfirmDeleteModalInputProps>>;
}>({
  showConfirmDeleteModal: false,
  setShowConfirmDeleteModal: () => null,
  confirmDeleteModalInputProps: {} as ConfirmDeleteModalInputProps,
  setConfirmDeleteModalInputProps: () => null,
});

export interface NavOption {
  name: string;
  isActive: boolean;
  link: string;
}
