import type { AxiosError } from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { FinanceAccount, TransactionSchema } from "client/data-contracts";
import DisplayError from "components/Common/DisplayError";
import {
  loadAccounts,
  selectAccounts,
  updateAccount,
} from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { createTransaction, updateTransaction } from "components/Finances/Transactions/TransactionsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { Nullish, NumberFieldValue } from "utils/base";
import getById from "utils/crud";
import { convertFormDateToUTC, DATE_INPUT_FORMAT, displayDate } from "utils/date";
import dayjs from "utils/dayjs";
import { convertMoneyToNumber, convertNumberToMoney } from "utils/finances";
import Logger from "utils/logger";

interface TransferTransactionsFormProps {
  entity: TransactionSchema;
}

interface Values {
  from_amount: NumberFieldValue;
  to_amount: NumberFieldValue;
  date: string;
  comment: string;
}

const validationSchema = object({
  from_amount: string().required(),
  to_amount: string(),
  date: string().required("Date is required."),
  comment: string(),
});

export default function TransferTransactionsForm({
  entity,
}: TransferTransactionsFormProps): React.JSX.Element {
  const defaultValues = (entityForm: Nullish<TransactionSchema>): Values => {
    return {
      from_amount: (entityForm?.amount ? convertMoneyToNumber(entityForm?.amount) : "") as NumberFieldValue,
      to_amount: (entityForm?.to_amount
        ? convertMoneyToNumber(entityForm?.to_amount)
        : "") as NumberFieldValue,
      date: entityForm?.date ? displayDate(entityForm?.date) : dayjs().format(DATE_INPUT_FORMAT),
      comment: entityForm?.comment || "",
    } as Values;
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accounts = useAppSelector(selectAccounts);
  const [formErrors, setFormErrors] = useState<Nullish<AxiosError>>(undefined);
  const [formValues, setFormValues] = useState<Values>(defaultValues(undefined));
  const [account, setAccount] = useState<Nullish<FinanceAccount>>(undefined);
  const [toAccount, setToAccount] = useState<Nullish<FinanceAccount>>(undefined);

  useEffect(() => {
    if (!accounts) {
      (async () => {
        const response = await api.finance.getListOfAccounts({});
        dispatch(loadAccounts(response.data));
      })();
    }
  }, [dispatch, accounts]);

  useEffect(() => {
    setFormValues(defaultValues(entity));

    if (entity?.account_id) {
      try {
        setAccount(getById(accounts, entity?.account_id));
      } catch (error) {
        Logger.exception(error as Error);
      }
    }
    if (entity?.to_account_id) {
      try {
        setToAccount(getById(accounts, entity?.to_account_id));
      } catch (error) {
        Logger.exception(error as Error);
      }
    }
  }, [entity, accounts]);

  const showToAccountField = account && toAccount ? account.currency !== toAccount.currency : false;

  const handleSubmitEvent = async (values: Values, actions: FormikHelpers<Values>) => {
    const fromAmount = convertNumberToMoney(values.from_amount as number);
    const originalTransaction = {
      ...entity,
      ...{
        amount: fromAmount,
        to_amount: showToAccountField ? convertNumberToMoney(values.to_amount as number) : fromAmount,
        date: convertFormDateToUTC(values.date).toISOString(),
        currency: account?.currency,
        to_currency: toAccount?.currency,
        comment: values.comment,
      },
    } as TransactionSchema;
    const action = entity?.id ? api.finance.updateTransaction : api.finance.createTransaction;
    try {
      const response = await action(originalTransaction);
      const updatedTransaction = response.data;

      if (entity?.id) {
        dispatch(updateTransaction(updatedTransaction));
      } else {
        dispatch(createTransaction(updatedTransaction));
      }

      if (updatedTransaction.account) {
        dispatch(updateAccount(updatedTransaction.account));
      }

      if (updatedTransaction.to_account) {
        dispatch(updateAccount(updatedTransaction.to_account));
      }

      navigate("/finances");
    } catch (requestError) {
      setFormErrors(requestError as AxiosError);
      actions.setSubmitting(false);
    }
  };

  const setAllFromAmount = (event: React.MouseEvent, props: FormikProps<Values>) => {
    const allAmount = account?.balance || 0;
    props.setFieldValue("from_amount", convertMoneyToNumber(allAmount));

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
        handleSubmitEvent(values, actions).then();
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form className="col-lg-3">
          <>
            <DisplayError error={formErrors} />

            <p className="text-secondary small" data-test="transactions-title">
              From {account?.name} to {toAccount?.name}.
            </p>

            <div className="mb-1">
              <Field name="from_amount">
                {({ field, meta }: FieldProps) => (
                  <label className="form-label" htmlFor="transactions-from-amount-input">
                    <div className="d-flex justify-content-between">
                      <span>from Amount</span>
                      <span>
                        <a
                          href="#"
                          onClick={(event) => setAllFromAmount(event, props)}
                          className="small text-secondary"
                          style={{ textDecorationStyle: "dashed" }}
                        >
                          transfer all
                        </a>
                      </span>
                    </div>
                    <div className="input-group mb-1">
                      <span data-test="transactions-from-currency" className="input-group-text">
                        {account && account.currency}
                      </span>
                      <input
                        autoFocus
                        inputMode="decimal"
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Amount"
                        id="transactions-from-amount-input"
                        data-test="transactions-from-amount-input"
                        name={field.name}
                        value={field.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          event.target.value = event.target.value.replace(",", ".");
                          props.setFieldValue("from_amount", event.target.value);
                        }}
                        onBlur={field.onBlur}
                      />
                    </div>
                  </label>
                )}
              </Field>
            </div>

            {showToAccountField && (
              <div className="mb-1">
                <Field name="to_amount">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="transactions-to-amount-input">
                      to Amount
                      <div className="input-group mb-1">
                        <span data-test="transactions-to-currency" className="input-group-text">
                          {toAccount && toAccount.currency}
                        </span>
                        <input
                          className={`form-control ${meta.error ? "is-invalid" : ""}`}
                          placeholder="Amount"
                          id="transactions-to-amount-input"
                          data-test="transactions-to-amount-input"
                          name={field.name}
                          value={field.value}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            event.target.value = event.target.value.replace(",", ".");
                            props.setFieldValue("to_amount", event.target.value);
                          }}
                          onBlur={field.onBlur}
                        />
                        {meta.touched && meta.error && (
                          <div data-test="transactions-to-amount-error" className="invalid-feedback">
                            {meta.error}
                          </div>
                        )}
                      </div>
                    </label>
                  )}
                </Field>
              </div>
            )}

            <div className="mb-1">
              <Field name="comment">
                {({ field, meta }: FieldProps) => (
                  <label className="form-label" htmlFor="transactions-comment-input">
                    Comment
                    <textarea
                      className={`form-control ${meta.error ? "is-invalid" : ""}`}
                      placeholder="Comment"
                      id="transactions-comment-input"
                      data-test="transfer-transactions-comment-input"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                    {meta.touched && meta.error && (
                      <div data-test="transactions-comment-error" className="invalid-feedback">
                        {meta.error}
                      </div>
                    )}
                  </label>
                )}
              </Field>
            </div>

            <div className="mb-1">
              <Field name="date">
                {({ field, meta }: FieldProps) => (
                  <label className="form-label" htmlFor="transactions-date-input">
                    Date
                    <input
                      type="date"
                      className={`form-control ${meta.error ? "is-invalid" : ""}`}
                      placeholder="Date"
                      id="transactions-date-input"
                      data-test="transactions-date-input"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                    {meta.touched && meta.error && (
                      <div data-test="transactions-date-error" className="invalid-feedback">
                        {meta.error}
                      </div>
                    )}
                  </label>
                )}
              </Field>
            </div>

            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary me-3"
                onClick={() => navigate("/finances")}
              >
                Close
              </button>
              <button
                data-test="transactions-submit-button"
                type="submit"
                className="btn btn-outline-primary"
                disabled={!props.isValid || props.isSubmitting}
              >
                Save
              </button>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
}
