import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nullish } from "utils/base";
import { TokenSchema, UserSchema } from "client/data-contracts";
import api from "utils/api";

interface HeaderProps {
  setToken(userToken: Nullish<TokenSchema>): void;
}

export default function Header({ setToken }: HeaderProps): React.JSX.Element {
  const [user, setUser] = useState<Nullish<UserSchema>>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.users.getAuthUserInfo();
        setUser(response.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
          // token was expired
          setToken(null);
        }
      }
    })();
  }, [setToken]);

  const handleLogOutEvent = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setToken(null);
  };

  const logoutLink = user ? (
    <ul className="navbar-nav ms-auto">
      <li className="nav-item">
        <a href="#" className="nav-link" onClick={handleLogOutEvent} data-test="logout-link">
          logout ({user.username})
        </a>
      </li>
    </ul>
  ) : (
    ""
  );

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          Keeper
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link to="/finances" className="nav-link">
                Finances
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/media/books" className="nav-link">
                Books
              </Link>
            </li>
          </ul>
          {logoutLink}
        </div>
      </div>
    </nav>
  );
}
