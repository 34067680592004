import React from "react";
import { useParams } from "react-router-dom";
import RegularTransactionsForm from "components/Finances/Transactions/Form/RegularTransactionsForm";
import TransferTransactionsForm from "components/Finances/Transactions/Form/TransferTransactionsForm";
import { selectTransactions } from "components/Finances/Transactions/TransactionsSlice";
import { useAppSelector } from "hooks";
import getById from "utils/crud";

export default function TransactionsEdit(): React.JSX.Element {
  const { type, transactionId } = useParams();
  const transactions = useAppSelector(selectTransactions);
  const transaction = getById(transactions, transactionId as string);
  return (
    <>
      {type === "transfer" && <TransferTransactionsForm entity={transaction} />}
      {type === "regular" && <RegularTransactionsForm entity={transaction} />}
    </>
  );
}
