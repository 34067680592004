/*eslint-disable */

import React from "react";

export const generatePreview = ({ style }: any): React.ReactElement => {
  /**
   * Default HTML5 preview worked really slow.
   * So, we are using our custom preview and touch backend.
   */
  if (window.screen.width > 1000) {
    style.top = "30px";
  } else {
    style.left = "2px";
    style.top = "27px";
  }

  return (
    <>
      <span className="custom-drag-preview" style={style} />
    </>
  );
};

/* eslint-enable */
