import React, { useRef, useState, useEffect } from "react";
import api from "utils/api";
import { BookInputSchema } from "client/data-contracts";
import type { AxiosError } from "axios";
import { Nullish } from "utils/base";
import DisplayError from "components/Common/DisplayError";

export default function BookAdd(): React.JSX.Element {
  const [requestError, setRequestError] = useState<Nullish<AxiosError>>(undefined);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  async function handleSubmitEvent(event: React.FormEvent) {
    event.preventDefault();
    setRequestError(null);
    const inputText = textareaRef.current?.value ?? "";

    let bookInput;
    try {
      bookInput = JSON.parse(inputText);
    } catch {
      // if it is not JSON, then it is a URL
      bookInput = {
        url: inputText,
      };
    }

    try {
      await api.books.createOrUpdateBookFromExport(bookInput as BookInputSchema);
      // with page reload for now
      window.location.href = "/media/books";
    } catch (apiError) {
      setRequestError(apiError as AxiosError);
    }
  }

  return (
    <div className="row">
      <div className="col-lg-6">
        <h3>Add or update a book</h3>

        <form>
          <textarea
            ref={textareaRef}
            rows={20}
            className="form-control"
            placeholder="Paste book JSON data here..."
          ></textarea>
          <div className="text-end">
            <button
              className="btn btn-primary mt-2"
              type="submit"
              onClick={(event) => {
                handleSubmitEvent(event);
              }}
            >
              Add
            </button>
          </div>
        </form>
      </div>

      <div className="col-lg-6">
        <DisplayError error={requestError} />
      </div>
    </div>
  );
}
