import React from "react";
import MoneyStatDisplay from "components/Finances/Common/MoneyStatDisplay";
import FinanceCategoriesList from "components/Finances/FinanceCategories/FinanceCategoriesList";
import { selectFinanceCategoriesMonthlySpendingStat } from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import { useAppSelector } from "hooks";
import { Link } from "react-router-dom";

export default function FinanceCategories(): React.JSX.Element {
  const monthlySpendingStat = useAppSelector(selectFinanceCategoriesMonthlySpendingStat);

  return (
    <>
      <FinanceCategoriesList />
      <div className="row mt-1 mb-2">
        <div className="col-12 small text-secondary" data-test="finance-categories-stat">
          {monthlySpendingStat && (
            <>
              <MoneyStatDisplay
                roundBigNumber={false}
                showOnlyTotal={false}
                displayPrefix
                stat={monthlySpendingStat}
              />
              <Link
                to="/finances/finance-categories/add"
                className="ms-1 link-success"
                data-test="finance-categories-create-button"
              >
                +
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
