import "components/Finances/FinanceAccounts/CircleFinanceAccountsList.scss";

import React, { useState } from "react";
import CircleItem from "components/Finances/Common/CircleItem";
import CircleFinanceAccountsItem from "components/Finances/FinanceAccounts/CircleFinanceAccountsItem";
import { selectAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { useAppSelector } from "hooks";

export default function CircleFinanceAccountsList(): React.JSX.Element {
  const accounts = useAppSelector(selectAccounts);
  const [displayedLevel, setDisplayedLevel] = useState(0);

  const displayedAccounts = accounts.filter((item) => {
    return (item.display_level as number) <= displayedLevel;
  });

  const maxDisplayLevel = Math.max(...accounts.map((item) => item.display_level as number));

  let onClickHandler;
  let actionLabel;
  if (displayedLevel < maxDisplayLevel) {
    actionLabel = "more";
    onClickHandler = () => {
      setDisplayedLevel(displayedLevel + 1);
    };
  } else {
    actionLabel = "less";
    onClickHandler = () => {
      setDisplayedLevel(0);
    };
  }

  const accountItems = displayedAccounts.map((item) => (
    <div data-test="favorite-account-item" className="col-3 col-sm-2 col-md-2 col-xl-1" key={item.id}>
      <CircleFinanceAccountsItem entity={item} />
    </div>
  ));

  return (
    <div className="mb-2 mt-2 favorite-accounts row">
      {accountItems}
      <div
        className="col-3 col-sm-2 col-md-2 col-xl-1"
        data-test="toggle-accounts-displayed-level"
        onClick={onClickHandler}
      >
        <CircleItem name={actionLabel} icon="" amount={undefined} currency={undefined} />
      </div>
    </div>
  );
}
