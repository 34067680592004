import React, { Dispatch, SetStateAction, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Currency, Income } from "client/data-contracts";
import { deleteIncome, selectIncomeList } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { ConfirmDeleteModalContext, ConfirmDeleteModalInputProps } from "utils/base";
import getById from "utils/crud";
import { displayMoneyWithCurrency } from "utils/finances";

interface IncomeListProps {
  setShowIncomeFormModal: Dispatch<SetStateAction<boolean>>;
  setIncomeFormEntity: Dispatch<SetStateAction<Income | undefined>>;
}

export default function IncomeList({
  setShowIncomeFormModal,
  setIncomeFormEntity,
}: IncomeListProps): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const incomeList = useAppSelector(selectIncomeList);
  const { setShowConfirmDeleteModal, setConfirmDeleteModalInputProps } =
    useContext(ConfirmDeleteModalContext);

  const deleteIncomeEvent = async (
    incomeId: string,
    setDeleteButtonDisabled: Dispatch<SetStateAction<boolean>>
  ) => {
    const income = getById(incomeList, incomeId);
    await api.finance.deleteIncome(income);
    dispatch(deleteIncome(income));
    setDeleteButtonDisabled(false);
  };

  const incomeRows = incomeList.map((item) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>{item.associated_account?.name}</td>
      <td>
        {displayMoneyWithCurrency(item.earnings as number, item.associated_account?.currency as Currency)}
      </td>
      <td>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-primary"
          data-test="income-edit-button"
          onClick={() => {
            setIncomeFormEntity(item);
            setShowIncomeFormModal(true);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-success"
          data-test="income-add-income-button"
          onClick={() => {
            navigate(`/finances/transactions/income/${item.id}/${item.associated_account_id}`);
          }}
        >
          Add income
        </button>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-danger"
          data-test="income-delete-button"
          onClick={() => {
            setShowConfirmDeleteModal(true);
            setConfirmDeleteModalInputProps({
              toDeleteName: item.name,
              toDeleteId: item.id as string,
              deleteAction: deleteIncomeEvent,
            } as ConfirmDeleteModalInputProps);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <table className="table table-responsive table-bordered table-striped table-sm" data-test="income-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Associated account</th>
          <th>Earnings</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {incomeList.length ? (
          incomeRows
        ) : (
          <tr>
            <td colSpan={6}>No data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
