import { Currency, FinanceAccount } from "client/data-contracts";

// TODO load it from BE as settings
export const MONEY_DIGITS = 100;
export const DEFAULT_TRANSACTIONS_LIMIT = 25;
export const DEFAULT_FINANCE_GROUPS_LIMIT = 12;
export const DEFAULT_CURRENCY = Currency.USD;

/**
 * The server doesn't keep decimal inputs for money records.
 * We need to normalize them before sending from the client (otherwise validation error will rise).
 * @param input
 */
export function convertNumberToMoney(input: number): number {
  // math round here to prevent precision issues
  return Math.round(input * MONEY_DIGITS);
}

export function convertMoneyToNumber(input: number): number {
  return input / MONEY_DIGITS;
}

export function displayMoney(input: number): string {
  let result = convertMoneyToNumber(input).toLocaleString("ru-RU", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // we don't want to display 0,00, but just 0
  if (result.endsWith(",00")) {
    result = result.replace(",00", "");
  }
  return result;
}

export function displayMoneyWithCurrency(input: number, currency: Currency, roundBigNumber = false): string {
  let roundedInput = input;
  if (roundBigNumber && input > 1000) {
    roundedInput = Math.round(input / 100) * 100;
  }
  const money = displayMoney(roundedInput);
  switch (currency) {
    case Currency.USD:
      return `$${money}`;
    case Currency.EUR:
      return `€${money}`;
    case Currency.RUB:
      return `${money} ₽`;
    case Currency.THB:
      return `฿${money}`;
    case Currency.KRW:
      return `₩${money}`;
    case Currency.HKD:
      return `HK$${money}`;
    default:
      throw new Error(`Unknown currency: ${currency}`);
  }
}

export const FinanceDNDItemTypes = {
  ACCOUNT: "account",
};

export function accountNameDisplay(account: FinanceAccount): string {
  return `${account.name} (${account.account_type}, ${account.currency})`;
}

export type AccountDragObject = {
  entity: FinanceAccount;
};
