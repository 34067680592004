import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import api from "utils/api";
import { convertMoneyToNumber } from "utils/finances";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const CHART_OPTIONS = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: "index" as const,
  },
  scales: {
    y: {
      ticks: {
        callback(value: any) {
          return `$${value}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    tooltip: {
      callbacks: {
        label(context: any) {
          return `$${context.parsed.y}`;
        },
        footer(tooltipItems: any) {
          const diff = tooltipItems[0].parsed.y - tooltipItems[1].parsed.y;
          return `$${diff.toFixed(2)}`;
        },
      },
    },
  },
};

const PERIODS = [
  {
    value: "1y",
    label: "1 year",
  },
  {
    value: "2y",
    label: "2 years",
  },
  {
    value: "3y",
    label: "3 years",
  },
];

export default function IncomeAndExpensesChart(): React.JSX.Element {
  const [chartData, setChartData] = useState<any>(null);
  const [dataPeriod, setDataPeriod] = useState<string>("1y");

  useEffect(() => {
    (async () => {
      const response = await api.finance.getIncomeAndExpensesStatisticsForFinanceGroup({
        period: dataPeriod,
      });
      const results = response.data;
      const labels = results.income.map((item: any) => item.date);
      const chartDataNotReactive = {
        labels,
        datasets: [
          {
            label: "Income",
            data: results.income.map((item: any) => convertMoneyToNumber(item.value)),
            borderColor: "rgb(25, 135, 84)",
            backgroundColor: "rgb(25, 135, 84)",
            tension: 0.2,
          },
          {
            label: "Expenses",
            data: results.expenses.map((item: any) => convertMoneyToNumber(item.value)),
            borderColor: "rgba(33, 37, 41, 0.3)",
            backgroundColor: "rgba(33, 37, 41, 0.3)",
            tension: 0.2,
          },
        ],
      };
      setChartData(chartDataNotReactive);
    })();
  }, [dataPeriod, setChartData]);

  const periodSwitchers = PERIODS.map((item, index) => (
    <span
      key={item.value}
      className={["me-2", "badge", item.value === dataPeriod ? "text-bg-success" : "text-bg-primary"].join(
        " "
      )}
      role="button"
      tabIndex={index}
      onClick={() => setDataPeriod(item.value)}
    >
      {item.label}
    </span>
  ));

  return (
    <>
      {chartData && (
        <>
          {periodSwitchers}
          <Line options={CHART_OPTIONS} data={chartData} />
        </>
      )}
    </>
  );
}
/* eslint-enable @typescript-eslint/no-explicit-any */
