import React from "react";
import { Currency, MoneyStat } from "client/data-contracts";
import { isDefined, Nullish } from "utils/base";
import { DEFAULT_CURRENCY, displayMoneyWithCurrency } from "utils/finances";

const CURRENCIES_ORDER = Object.keys(Currency);

interface MoneyStatProps {
  stat: Nullish<MoneyStat>;
  displayPrefix: boolean;
  showOnlyTotal: boolean;
  roundBigNumber: boolean;
}

export default function MoneyStatDisplay({
  stat,
  displayPrefix,
  showOnlyTotal,
  roundBigNumber,
}: MoneyStatProps): React.JSX.Element {
  if (!isDefined(stat)) {
    return <span />;
  }

  let allCurrenciesAmountDisplay = "";
  if (!showOnlyTotal && stat.all_currencies_amount) {
    const currenciesKeys = Object.keys(stat.all_currencies_amount);
    allCurrenciesAmountDisplay = currenciesKeys
      .sort((a, b) => CURRENCIES_ORDER.indexOf(a) - CURRENCIES_ORDER.indexOf(b))
      .map((key) => {
        const currency = Currency[key as Currency];
        // there is no need to display the total amount if it is the same as the default currency amount
        if (currency === DEFAULT_CURRENCY && currenciesKeys.length === 1) {
          return "";
        }
        const amount = stat.all_currencies_amount[key as Currency];
        return `${displayMoneyWithCurrency(amount, currency, roundBigNumber)}`;
      })
      .join("; ");
  }

  return (
    <span>
      <span data-test="default-currency-amount">
        {displayPrefix ? "Total:" : ""} ~
        {displayMoneyWithCurrency(stat.default_currency_amount, DEFAULT_CURRENCY, roundBigNumber)}&nbsp;
      </span>
      {!showOnlyTotal && allCurrenciesAmountDisplay !== "" ? (
        <span data-test="all-currencies-amount">({allCurrenciesAmountDisplay})</span>
      ) : (
        ""
      )}
    </span>
  );
}
