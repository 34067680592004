import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { FinanceAccount } from "client/data-contracts";
import type { RootState } from "store";
import { Nullish } from "utils/base";

interface AccountsState {
  accountsResponse: Nullish<FinanceAccount[]>;
}

const initialState: AccountsState = {
  accountsResponse: null,
};

const sortFunction = (a: FinanceAccount, b: FinanceAccount) => {
  if (a.display_level !== b.display_level) {
    return (a.display_level || 0) - (b.display_level || 0);
  }
  return (a.order || 0) - (b.order || 0);
};

export const financeAccountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    loadAccounts: (state, action: PayloadAction<FinanceAccount[]>) => {
      state.accountsResponse = action.payload.sort(sortFunction);
    },
    deleteAccount: (state, action: PayloadAction<FinanceAccount>) => {
      if (!state.accountsResponse) {
        return;
      }
      state.accountsResponse = state.accountsResponse.filter((f) => f.id !== action.payload.id);
    },
    updateAccount: (state, action: PayloadAction<FinanceAccount>) => {
      if (!state.accountsResponse) {
        return;
      }
      state.accountsResponse = state.accountsResponse
        .map((item) => (item.id === action.payload.id ? action.payload : item))
        .sort(sortFunction);
    },
    createAccount: (state, action: PayloadAction<FinanceAccount>) => {
      if (!state.accountsResponse) {
        return;
      }
      state.accountsResponse = [...state.accountsResponse, action.payload].sort(sortFunction);
    },
  },
});

export const { loadAccounts, createAccount, deleteAccount, updateAccount } = financeAccountsSlice.actions;

export const selectAccounts = createSelector(
  (state: RootState) => state.accounts.accountsResponse,
  (accountsResponse) => accountsResponse || []
);

export default financeAccountsSlice.reducer;
