import React from "react";
import { useDrop } from "react-dnd";
import { FaMoneyBillWave, FaShoppingBag, FaWrench } from "react-icons/fa";
import { GiFamilyHouse, GiHamburger, GiInvertedDice6, GiWorld } from "react-icons/gi";
import { IoBusOutline } from "react-icons/io5";
import { RiShoppingBasket2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CircleItem from "components/Finances/Common/CircleItem";
import { FinanceCategorySchema, FinanceCategoryType } from "client/data-contracts";
import { AccountDragObject, DEFAULT_CURRENCY, FinanceDNDItemTypes } from "utils/finances";

interface FinanceCategoriesItemProps {
  entity: FinanceCategorySchema;
  editAction(): void;
}

function getIcon(category: FinanceCategorySchema): React.ReactNode {
  if (category.category_type === FinanceCategoryType.PRODUCTS) {
    return <RiShoppingBasket2Line />;
  }
  if (category.category_type === FinanceCategoryType.RESTAURANTS) {
    return <GiHamburger />;
  }
  if (category.category_type === FinanceCategoryType.TRANSPORT) {
    return <IoBusOutline />;
  }
  if (category.category_type === FinanceCategoryType.SERVICES) {
    return <FaWrench />;
  }
  if (category.category_type === FinanceCategoryType.ENTERTAINMENT) {
    return <GiInvertedDice6 />;
  }
  if (category.category_type === FinanceCategoryType.HOUSE) {
    return <GiFamilyHouse />;
  }
  if (category.category_type === FinanceCategoryType.SHOPPING) {
    return <FaShoppingBag />;
  }
  if (category.category_type === FinanceCategoryType.OTHER) {
    return <GiWorld />;
  }
  return <FaMoneyBillWave />;
}

export default function FinanceCategoriesItem({
  entity,
  editAction,
}: FinanceCategoriesItemProps): React.JSX.Element {
  const navigate = useNavigate();
  const [, drop] = useDrop(
    () => ({
      accept: FinanceDNDItemTypes.ACCOUNT,
      drop: (item: AccountDragObject) => {
        navigate(`/finances/transactions/regular/${item.entity.id}/${entity.id}`);
      },
    }),
    []
  );

  return (
    <span ref={drop}>
      <CircleItem
        name={entity.name}
        icon={getIcon(entity)}
        amount={entity.amount as number}
        currency={DEFAULT_CURRENCY}
        editAction={editAction}
      />
    </span>
  );
}
