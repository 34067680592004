import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { FinanceGroupSchema, FinanceGroupType } from "client/data-contracts";
import MoneyStatDisplay from "components/Finances/Common/MoneyStatDisplay";
import IncomeAndExpensesChart from "components/Finances/FinanceGroups/IncomeAndExpensesChart";
import api from "utils/api";
import { displayDate } from "utils/date";
import { DEFAULT_FINANCE_GROUPS_LIMIT } from "utils/finances";

const FINANCE_GROUP_CARD_CLASS_MAPPINGS = {
  [FinanceGroupType.MONTHLY_EXPENSES]: {
    cardClass: "",
    linkClass: "link-dark",
  },
  [FinanceGroupType.PERIODICAL]: {
    cardClass: "text-bg-secondary",
    linkClass: "link-light",
  },
  [FinanceGroupType.MONTHLY_INCOME]: {
    cardClass: "text-bg-success",
    linkClass: "link-light",
  },
  [FinanceGroupType.DATA_BASED]: {
    cardClass: "text-bg-primary",
    linkClass: "link-light",
  },
};

export default function FinanceGroupsList(): React.JSX.Element {
  const [financeGroupsOffset, setFinanceGroupsOffset] = useState(0);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [financeGroups, setFinanceGroups] = useState<FinanceGroupSchema[]>([]);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfFinanceGroups({
        offset: 0,
        limit: DEFAULT_FINANCE_GROUPS_LIMIT,
      });
      setFinanceGroups(response.data);
    })();
  }, [setFinanceGroups]);

  async function loadMoreFinanceGroupsEvent(event: SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();

    const newOffset = financeGroupsOffset + DEFAULT_FINANCE_GROUPS_LIMIT;
    setFinanceGroupsOffset(newOffset);

    const response = await api.finance.getListOfFinanceGroups({
      offset: newOffset,
      limit: DEFAULT_FINANCE_GROUPS_LIMIT,
    });
    const newFinanceGroups = response.data;

    if (newFinanceGroups.length === 0 || newFinanceGroups.length < DEFAULT_FINANCE_GROUPS_LIMIT) {
      setShowLoadMoreButton(false);
    }

    if (newFinanceGroups.length) {
      setFinanceGroups((prevState) => {
        return prevState.concat(newFinanceGroups);
      });
    }

    return false;
  }

  const financeGroupsListDisplay = financeGroups.map((item) => {
    const classesMapping = FINANCE_GROUP_CARD_CLASS_MAPPINGS[item.group_type];
    const { cardClass } = classesMapping;
    const { linkClass } = classesMapping;
    return (
      <div className="col-lg-4 mt-3" key={item.id} data-test="finance-groups-item">
        <div className={`card ${cardClass}`}>
          <div className="card-header">{item.name}</div>
          <div className="card-body">
            <p className="card-text mb-1">
              From {displayDate(item.start_date || item.statistics?.first_transaction_date, true)} to{" "}
              {displayDate(item.end_date || item.statistics?.last_transaction_date, true)}
              <br />
              <span className="fs-6">
                <MoneyStatDisplay roundBigNumber showOnlyTotal displayPrefix stat={item.statistics?.all} />
              </span>
            </p>
            <Link data-test="finance-groups-item-link" to={`${item.id}`} className={linkClass}>
              Details
            </Link>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <IncomeAndExpensesChart />
      <div className="row mb-3">{financeGroupsListDisplay}</div>
      {showLoadMoreButton && (
        <div className="mb-3">
          <button
            type="button"
            onClick={loadMoreFinanceGroupsEvent}
            data-test="finance-groups-load-more-items"
            className="btn btn-outline-success"
          >
            Load more
          </button>
        </div>
      )}

      <Outlet />
    </>
  );
}
