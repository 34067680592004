import type { AxiosError } from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { Income } from "client/data-contracts";
import CustomModal from "components/Common/CustomModal";
import DisplayError from "components/Common/DisplayError";
import { selectAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { createIncome, updateIncome } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { ModalVisibilityProps, Nullish } from "utils/base";
import { accountNameDisplay } from "utils/finances";

interface IncomeModalFormProps extends ModalVisibilityProps {
  onHide(): void;
  entity: Income | undefined;
}

interface Values {
  incomeName: string;
  accountId: string;
}

const validationSchema = object({
  incomeName: string().required("Income name is required."),
  accountId: string().required("Account is required."),
});

export default function IncomeModalForm({
  showModal,
  setShowModal,
  onHide,
  entity,
}: IncomeModalFormProps): React.JSX.Element {
  const defaultValues = (entityForm: Income | undefined): Values => {
    return {
      incomeName: entityForm?.name || "",
      accountId: entityForm?.associated_account_id || "",
    } as Values;
  };
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const [formErrors, setFormErrors] = useState<Nullish<AxiosError>>(undefined);
  const [formValues, setFormValues] = useState<Values>(defaultValues(undefined));

  useEffect(() => {
    setFormValues(defaultValues(entity));
  }, [entity]);

  const handleHideEvent = (actions: FormikHelpers<Values>) => {
    actions.setSubmitting(false);
    actions.resetForm();
    onHide();
  };

  const handleSubmitEvent = async (values: Values, actions: FormikHelpers<Values>) => {
    const income = {
      ...entity,
      ...{ name: values.incomeName, associated_account_id: values.accountId },
    } as Income;
    const action = entity?.id ? api.finance.updateIncome : api.finance.createIncome;

    try {
      const response = await action(income);
      if (entity?.id) {
        dispatch(updateIncome(response.data));
      } else {
        dispatch(createIncome(response.data));
      }
      handleHideEvent(actions);
    } catch (requestError) {
      setFormErrors(requestError as AxiosError);
      actions.setSubmitting(false);
    }
  };

  const accountOptions = accounts.map((item) => (
    <option key={item.id} value={item.id}>
      {accountNameDisplay(item)}
    </option>
  ));

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
        handleSubmitEvent(values, actions);
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form>
          <CustomModal
            modalTitle="Create Income"
            modalName="income"
            modalBody={
              <>
                <DisplayError error={formErrors} />

                <div className="mb-3">
                  <Field name="incomeName">
                    {({ field, meta }: FieldProps) => (
                      <label className="form-label" htmlFor="income-name-input">
                        Income Name
                        <input
                          type="text"
                          className={`form-control ${meta.error ? "is-invalid" : ""}`}
                          placeholder="Income Name"
                          data-test="income-name-input"
                          id="income-name-input"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                        {meta.touched && meta.error && (
                          <div data-test="income-name-error" className="invalid-feedback">
                            {meta.error}
                          </div>
                        )}
                      </label>
                    )}
                  </Field>
                </div>

                <div className="mb-3">
                  <Field name="accountId">
                    {({ field, meta }: FieldProps) => (
                      <label className="form-label" htmlFor="income-account-id-input">
                        Account
                        <select
                          className={`form-control ${meta.error ? "is-invalid" : ""}`}
                          data-test="income-account-id-input"
                          id="income-account-id-input"
                          value={field.value}
                          name={field.name}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        >
                          <option value="">select an option</option>
                          {accountOptions}
                        </select>
                        {meta.touched && meta.error && (
                          <div data-test="income-account-id-error" className="invalid-feedback">
                            {meta.error}
                          </div>
                        )}
                      </label>
                    )}
                  </Field>
                </div>
              </>
            }
            submitButtonDisabled={!props.isValid || props.isSubmitting}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </Form>
      )}
    </Formik>
  );
}
