import { configureStore } from "@reduxjs/toolkit";
import accountsReducer from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import financeCategoriesReducer from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import incomeReducer from "components/Finances/Income/IncomeSlice";
import transactionsReducer from "components/Finances/Transactions/TransactionsSlice";

export const store = configureStore({
  reducer: {
    financeCategories: financeCategoriesReducer,
    income: incomeReducer,
    accounts: accountsReducer,
    transactions: transactionsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
