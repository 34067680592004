import "components/Finances/Transactions/TransactionsList.scss";

import React, { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { TransactionSchema, TransactionType } from "client/data-contracts";
import { updateAccount } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { updateCategoryAmountFromTransaction } from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import TransactionsListItem from "components/Finances/Transactions/TransactionsListItem";
import {
  deleteTransaction,
  loadTransactions,
  selectLatestSearchQuery,
  selectTransactions,
  selectTransactionsOffset,
  setLatestSearchQuery,
  setTransactionsOffset,
} from "components/Finances/Transactions/TransactionsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import getById from "utils/crud";
import { displayDatetime } from "utils/date";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FINANCE_GROUPS_LIMIT,
  DEFAULT_TRANSACTIONS_LIMIT,
  displayMoneyWithCurrency,
} from "utils/finances";

export default function TransactionsList(): React.JSX.Element {
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(selectTransactions);
  const transactionsOffset = useAppSelector(selectTransactionsOffset);
  const searchQuery = useAppSelector(selectLatestSearchQuery);

  const deleteTransactionEvent = async (
    transactionId: string,
    setDeleteButtonDisabled: Dispatch<SetStateAction<boolean>>
  ) => {
    const transaction = getById(transactions, transactionId);
    const response = await api.finance.deleteTransaction(transaction);
    dispatch(deleteTransaction(transaction));

    const updatedAccount = response.data.account;
    if (updatedAccount) {
      dispatch(updateAccount(updatedAccount));
    }

    if (transaction.category_id) {
      dispatch(
        updateCategoryAmountFromTransaction({
          categoryId: transaction.category_id,
          transaction,
          increase: false,
        })
      );
    }

    setDeleteButtonDisabled(false);
  };

  async function performTransactionsAPICall(query: { offset: number; limit: number; query?: string }) {
    const response = await api.finance.getListOfTransactions(query);
    const newTransactions = response.data;

    if (newTransactions.length === 0 || newTransactions.length < DEFAULT_FINANCE_GROUPS_LIMIT) {
      setShowLoadMoreButton(false);
    }

    return newTransactions;
  }

  async function loadMoreTransactionsEvent(event: SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();

    const newTransactionsOffset = transactionsOffset + DEFAULT_TRANSACTIONS_LIMIT;
    dispatch(setTransactionsOffset(newTransactionsOffset));

    const results = await performTransactionsAPICall({
      offset: newTransactionsOffset,
      limit: DEFAULT_TRANSACTIONS_LIMIT,
      query: searchQuery,
    });

    if (results.length) {
      dispatch(loadTransactions(transactions.concat(results)));
    }

    return false;
  }

  async function doSearchEvent(event: SyntheticEvent) {
    event.preventDefault();
    event.stopPropagation();

    setShowLoadMoreButton(true);

    const newTransactionsOffset = 0;
    dispatch(setTransactionsOffset(newTransactionsOffset));

    const results = await performTransactionsAPICall({
      offset: 0,
      limit: DEFAULT_TRANSACTIONS_LIMIT,
      query: searchQuery,
    });

    dispatch(loadTransactions(results));

    return false;
  }

  const mappedTransactions: { [name: string]: TransactionSchema[] } = {};
  const spendingPerDay: { [name: string]: number } = {};
  transactions.forEach((item) => {
    const date = displayDatetime(item.date);
    if (!(date in mappedTransactions)) {
      mappedTransactions[date] = [];
    }
    if (!(date in spendingPerDay)) {
      spendingPerDay[date] = 0;
    }

    mappedTransactions[date].push(item);
    const amount = item.equivalent_amount_in_usd ? item.equivalent_amount_in_usd : item.amount;
    if (item.type === TransactionType.REGULAR) {
      spendingPerDay[date] += amount;
    }
  });

  const transactionRows = Object.keys(mappedTransactions).map((date) => {
    const items = mappedTransactions[date];
    const spendingPerDayValue = spendingPerDay[date];
    const itemsHtml = items.map((item) => (
      <TransactionsListItem key={item.id} deleteTransaction={deleteTransactionEvent} item={item} />
    ));
    return (
      <div className="row" key={date}>
        <div className="col-12">
          <h5 className="m1-2">{date}</h5>
        </div>
        {itemsHtml}
        <div className="col-12 mt-2">
          <p className="fs-7">
            Spending per day:{" "}
            {spendingPerDayValue !== 0 ? (
              <span className="text-danger">
                {`-${displayMoneyWithCurrency(spendingPerDayValue, DEFAULT_CURRENCY)}`}
              </span>
            ) : (
              "$0"
            )}
          </p>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <h4 className="d-inline" data-test="transactions-list-header">
            Transactions
          </h4>
        </div>
        <div className="col-12 col-md-auto">
          <form className="form-inline d-flex flex-wrap mt-1 mb-1">
            <div className="col col-md-auto p-0 me-2">
              <input
                data-test="transactions-search-input"
                value={searchQuery}
                onChange={(evt) => dispatch(setLatestSearchQuery(evt.target.value))}
                className="form-control form-control-sm w-100 mb-1"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </div>
            <div className="col-auto p-0">
              <button
                data-test="transactions-search-submit"
                className="btn btn-outline-primary btn-sm w-100"
                type="submit"
                onClick={doSearchEvent}
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="transactions">{transactions ? transactionRows : ""}</div>
      <div>
        {showLoadMoreButton ? (
          <button
            type="button"
            onClick={loadMoreTransactionsEvent}
            data-test="transactions-load-more-items"
            className="btn btn-outline-success mb-3"
          >
            Load more transactions
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
