import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "utils/api";
import { BookReadingStatus, BookSchema, ReadingHistory } from "client/data-contracts";
import { Nullish } from "utils/base";
import type { AxiosError } from "axios";
import DisplayError from "components/Common/DisplayError";

export default function BookView(): React.JSX.Element {
  const { bookId } = useParams();
  const [book, setBook] = useState<BookSchema | null>(null);
  const [requestError, setRequestError] = useState<Nullish<AxiosError>>(undefined);
  const [score, setScore] = useState<number>(5);
  const [status, setStatus] = useState<BookReadingStatus>(BookReadingStatus.Done);
  const [comment, setComment] = useState<string>("");

  async function deleteBookHandler() {
    if (!book) {
      return;
    }

    if (window.confirm("Are you sure you want to delete this book?")) {
      try {
        await api.books.deleteBook(book);
        window.location.href = "/media/books";
      } catch (error) {
        setRequestError(error as AxiosError);
      }
    }
  }

  async function finishReadingHandler(event: React.FormEvent) {
    event.preventDefault();
    try {
      const readingHistory = book?.reading_history?.[0] as ReadingHistory;
      readingHistory.score = score;
      readingHistory.comment = comment;
      readingHistory.status = status;
      await api.books.finishReadingBook(bookId as string, readingHistory);
      // with page reload for now
      window.location.href = "/media/books";
    } catch (error) {
      setRequestError(error as AxiosError);
    }
  }

  useEffect(() => {
    (async () => {
      const response = await api.books.getBookDetails(bookId as string);
      setBook(response.data);
    })();
  }, [bookId]);

  return (
    <div className="row">
      <div className="col-lg-6">
        {book && (
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>Book content</h3>
              <button className="btn btn-danger" onClick={deleteBookHandler} type="button">
                Delete Book
              </button>
            </div>
            <code>
              <pre>{JSON.stringify(book, null, 2)}</pre>
            </code>
          </div>
        )}
      </div>
      <div className="col-lg-6">
        <DisplayError error={requestError} />
        {book && !book.reading_history?.[0].date_end && (
          <form onSubmit={finishReadingHandler} className="mt-4">
            <div className="mb-3">
              <label htmlFor="score" className="form-label">
                Score (1-10)
              </label>
              <select
                className="form-select"
                id="score"
                value={score}
                onChange={(e) => setScore(Number(e.target.value))}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="score" className="form-label">
                Status
              </label>
              <select
                className="form-select"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value as BookReadingStatus)}
              >
                {[BookReadingStatus.Done, BookReadingStatus.Dropped].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="comment" className="form-label">
                Comment
              </label>
              <textarea
                className="form-control"
                id="comment"
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-primary">
                Finish reading
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
