import type { AxiosError } from "axios";
import React from "react";
import { Nullish } from "utils/base";

interface DisplayErrorProps {
  error: Nullish<AxiosError>;
}

export default function DisplayError({ error }: DisplayErrorProps): React.JSX.Element {
  if (!error) {
    return <span />;
  }
  const errorString = JSON.stringify(error.response?.data, null, 2);
  return (
    <>
      <span>
        <span className="badge text-bg-danger" data-test="error-block-title">
          Error {error.response?.status}
        </span>
      </span>
      <code>
        <pre>{errorString}</pre>
      </code>
    </>
  );
}
