import "components/Finances/CurrencyExchangeRates/CurrencyExchangeRates.scss";

import React from "react";
import DepositAccounts from "components/Finances/Investments/DepositAccounts";

export default function Investments(): React.JSX.Element {
  return (
    <>
      <h2>Investments</h2>

      <p>Passive income: NA</p>
      <p>Estimated passive income: NA</p>

      <DepositAccounts />
    </>
  );
}
