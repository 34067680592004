import React, { useState } from "react";
import { FinanceAccount } from "client/data-contracts";
import FinanceAccountsList from "components/Finances/FinanceAccounts/FinanceAccountsList";
import FinanceAccountsModalForm from "components/Finances/FinanceAccounts/FinanceAccountsModalForm";
import DepositAccounts from "components/Finances/Investments/DepositAccounts";

export default function FinanceAccounts(): React.JSX.Element {
  const [showAccountsFormModal, setShowAccountsFormModal] = useState(false);
  const [accountsFormEntity, setAccountsFormEntity] = useState<FinanceAccount | undefined>(undefined);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-2 d-flex">
          <h3 data-test="accounts-list-header">Accounts</h3>
          <button
            type="button"
            data-test="accounts-create-button"
            className="ms-3 btn btn-sm btn-outline-success ml-auto mt-1"
            style={{ height: "31px" }}
            onClick={() => setShowAccountsFormModal(true)}
          >
            Create
          </button>
        </div>
      </div>
      <FinanceAccountsList
        setShowAccountsFormModal={setShowAccountsFormModal}
        setAccountsFormEntity={setAccountsFormEntity}
      />
      <DepositAccounts />
      <FinanceAccountsModalForm
        showModal={showAccountsFormModal}
        setShowModal={setShowAccountsFormModal}
        onHide={() => setShowAccountsFormModal(false)}
        entity={accountsFormEntity}
      />
    </>
  );
}
