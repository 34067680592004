import React from "react";
import { TransactionTag } from "client/data-contracts";
import { Nullish } from "utils/base";

interface TransactionsTagsDisplayProps {
  tags: Nullish<TransactionTag[]>;

  onDelete?: (tag: TransactionTag) => void;
}

export default function TransactionsTagsDisplay({
  tags,
  onDelete = undefined,
}: TransactionsTagsDisplayProps): React.JSX.Element {
  const sortedTags = (tags || []).slice().sort((a, b) => (a.name > b.name ? 1 : -1));
  const tagsDisplay = sortedTags.map((tag) => (
    <span className="badge text-bg-primary me-1" key={tag.name}>
      {tag.name}
      {onDelete && (
        <button type="button" className="btn-close btn-close-white ms-2 small" onClick={() => onDelete(tag)}>
          &times;
        </button>
      )}
    </span>
  ));

  return <>{tagsDisplay}</>;
}
