import FinanceCategoriesForm from "components/Finances/FinanceCategories/Form/FinanceCategoriesForm";
import React from "react";

export default function FinanceCategoriesAdd(): React.JSX.Element {
  return (
    <>
      <h3 data-test="finance-categories-add-title">Add finance category</h3>
      <FinanceCategoriesForm entity={undefined} />
    </>
  );
}
