import { Modal } from "bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "hooks";
import { ModalVisibilityProps, Nullish } from "utils/base";

interface CustomModalProps extends ModalVisibilityProps {
  modalTitle: string;
  modalBody: React.JSX.Element;
  modalName: string;
  submitButtonDisabled: boolean;
  submitButtonType?: "submit" | "button";
  submitButtonClass?: string;
  submitButtonText?: string;
  submitButtonCallback?: () => void;
}

export default function CustomModal({
  modalTitle,
  modalBody,
  modalName,
  showModal,
  setShowModal,
  submitButtonDisabled,
  submitButtonType = "submit",
  submitButtonClass = "btn-outline-primary",
  submitButtonCallback,
  submitButtonText = "Save",
}: CustomModalProps): React.JSX.Element {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<Nullish<Modal>>(null);
  const modalObject = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    setModal(
      new Modal(modalObject.current as Element, {
        // TODO handle Bootstrap hide events to properly use setShowModal(false) on that
        // for now disable these functions
        backdrop: "static",
        keyboard: false,
      })
    );
  }, []);

  useEffect(() => {
    if (!modal) {
      return;
    }

    if (showModal) {
      modal.show();
    } else {
      modal.hide();
    }
  }, [showModal, modal]);

  return (
    <div className="modal fade" ref={modalObject}>
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" data-test="modal-form-title">
              {modalTitle}
            </h5>
            <button
              tabIndex={-1}
              type="button"
              className="btn-close"
              onClick={() => dispatch(setShowModal(false))}
              aria-label="Close"
            />
          </div>
          <div className="modal-body">{modalBody}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => dispatch(setShowModal(false))}
            >
              Close
            </button>
            <button
              data-test={`modal-form-submit-button-${modalName}`}
              type={submitButtonType ? "submit" : "button"}
              className={`btn ${submitButtonClass}`}
              disabled={submitButtonDisabled}
              onClick={() => submitButtonCallback && submitButtonCallback()}
            >
              {submitButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
